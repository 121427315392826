import { Button, Dialog, List, ListItem, ListItemButton, Typography, TextareaAutosize } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import React, { useState } from 'react';

// Styled component for the dialog content
const DialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
});

const IssueDialog = (props) => {
  const [checked, setChecked] = useState([]);
  const [comment, setComment] = useState('');

  const handleClickValidate = () => {
    if (checked.length === 0) {
      const confirmed = window.confirm("Aucun problème n'a été sélectionné, êtes vous sur de vouloir confirmer ?");
      if (confirmed) {
        props.handleClickValidateIssues(checked);
      }
    } else {
      props.handleClickValidateIssues(checked, comment);
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <Dialog open={props.open} maxWidth="xs" margin="auto">
      <DialogContent>
        <Typography variant="h5" align="center" gutterBottom>
          Please select the type(s) of problem(s)
        </Typography>
        <List>
          {props.issues.map((issue) => (
            <ListItem key={issue.issueId}>
              <ListItemButton onClick={handleToggle(issue)}>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(issue) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
                <Typography variant="body1">{issue.name}</Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Typography variant="h6" align="center" gutterBottom>
          Comments :
        </Typography>
        <TextareaAutosize
          rows={4}
          placeholder="Ajouter des commentaires..."
          value={comment}
          onChange={handleCommentChange}
          style={{ width: '100%', marginBottom: '16px', padding: '8px' }}
        />
        <Button onClick={handleClickValidate} variant="contained" color="primary">
          Validate
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default IssueDialog;
