import { Button, Dialog, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';

const OrderTasksDialog = ({ open, tasks, initialOrder, handleClickValidateOrderTasks }) => {
  const [orderedTasks, setOrderedTasks] = useState(tasks);

  const DialogContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
  });

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const updatedTasks = Array.from(orderedTasks);
    const [draggedTask] = updatedTasks.splice(source.index, 1);
    updatedTasks.splice(destination.index, 0, draggedTask);

    setOrderedTasks(updatedTasks);
  };

  const handleSaveOrder = () => {
    handleClickValidateOrderTasks(orderedTasks);
  };

  return (
    <Dialog open={open}>
        <DialogContent>
      <Typography variant="h5" align="center" gutterBottom>
        Tasks reorganization
      </Typography>
      <Typography variant="h8" align="center" gutterBottom>
        Drag the tasks into the desired order and click Save
      </Typography>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="task-list">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {orderedTasks.map((task, index) => (
                <Draggable key={task.taskId} draggableId={`task-${task.taskId}`} index={index}>
                  {(provided) => (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ListItemText primary={task.name} />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <Button variant="contained" color="primary" onClick={handleSaveOrder}>
        Save
      </Button>
      </DialogContent>
    </Dialog>
  );
};

export default OrderTasksDialog;