import { Container, Paper, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { grey, purple } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntlMessages from "util/IntlMessages";
import { isTokenValid, userSignIn } from "../../../redux/actions/Auth";

const SignIn = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const validToken = useSelector(({ auth }) => auth.isValidToken);
  const authUser = useSelector(({ auth }) => auth.authUser);
  //const { loading } = useSelector(({ commonData }) => commonData);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        var email = emailInputRef.current.value;
        var password = passwordInputRef.current.value;
        dispatch(userSignIn({ email, password }));
      }
    };
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [dispatch]);

  useEffect(() => {
    if (token !== null) {
      if (validToken) {
        if (authUser.isPasswordReset) {
          navigate("/PasswordReset");
        } else {
          navigate("/");
        }
      } else {
        dispatch(isTokenValid(token));
      }
    }
  }, [token, validToken, dispatch, navigate, authUser]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    var email = emailInputRef.current.value;
    var password = passwordInputRef.current.value;
    dispatch(userSignIn({ email, password }));
  };

  return (
    <Container
      sx={{
        backgroundColor: grey[900],
        margin: 0,
        maxWidth: "none !important",
      }}
    >
      <Stack
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Paper elevation={3} sx={{ padding: 5, width: "100%", maxWidth: 450 }}>
          <Typography variant="h2" component="h1" align="center">
            Welcome back !
          </Typography>
          <form onSubmit={onFormSubmit}>
            <Stack
              justifyContent="center"
              spacing={2}
              sx={{ width: "100%", marginTop: 3 }}
            >
              <TextField
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                required={true}
                inputRef={emailInputRef}
              />
              <TextField
                type="password"
                label={<IntlMessages id="appModule.password" />}
                fullWidth
                required={true}
                inputRef={passwordInputRef}
              />

              <Button variant="contained" color="primary" type="submit">
                <IntlMessages id="appModule.signIn" />
              </Button>
            </Stack>
          </form>
        </Paper>
      </Stack>

      <ToastContainer />
    </Container>
  );
};

export default SignIn;
